<template>
  <BaseModal
    :title="_T('@Subscription popup title')"
    :modal-width="620"
    background-image="url('/img/percent_bg.png')"
    close-button-color="white"
    @on-close-modal="onCloseModal"
  >
    <img
      class="percent-icon percent-icon__white-big"
      :src="'/img/percent_white_big.png'"
      alt="percent"
    />
    <img
      class="percent-icon percent-icon__white"
      :src="'/img/percent_white_big.png'"
      alt="percent"
    />
    <img
      class="percent-icon percent-icon__green-big"
      :src="'/img/percent_green_big.png'"
      alt="percent"
    />
    <img
      class="percent-icon percent-icon__green"
      :src="'/img/percent_green_big.png'"
      alt="percent"
    />
    <form action="" class="modal-discount">
      <p class="modal-discount__title">
        {{ _T("@Description subscription pop-up") }}
      </p>
      <InputText
        v-model="form.name"
        :validate="v.name"
        :maxlength="255"
        :caption="_T('@Name')"
        autocomplete-type="given-name"
      />
      <InputMail v-model="form.email" :validate="v.email" caption="E-mail" />
      <BaseCheckbox
        v-model="form.isAgreementAccepted"
        :validate="v.isAgreementAccepted"
        color="white"
      >
        <span>
          {{ _T("@I agree with") }}
          <MyLink name="privacy-policy" target="_blank">
            {{ _T("@privacy policy") }}
          </MyLink>
        </span>
      </BaseCheckbox>
      <ButtonSecondary
        type="submit"
        :aria-label="_T('@Send')"
        button-width="363px"
        :disabled="sendForm.isHandled"
        @click.prevent="onSubmit"
      >
        {{ _T("@Send") }}
      </ButtonSecondary>
    </form>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~/modules/shared/inputs/InputText.vue";
import InputMail from "~/modules/shared/inputs/InputEmail.vue";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import { useDiscountModalStore } from "~/store/discountModalStore";
import ButtonSecondary from "~/modules/shared/buttons/ButtonSecondary.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();
const discountModalStore = useDiscountModalStore();

const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  if (null !== user.value) {
    form.name = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "discount" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function onCloseModal() {
  discountModalStore.setModalShown(true);
}

const sendForm = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "subscriptionWithCoupon",
      email: form.email,
      isConfirmed: true,
      name: form.name,
    },
  }).then(() => {
    closeModal();

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-discount {
  position: relative;

  @include flex-container(column, center, center);
  gap: 24px;

  &__title {
    width: min(100%, 363px);
    text-align: center;

    @include font(18, 25, 600);
    letter-spacing: 0.36px;
    color: white;
  }
}

.percent-icon {
  position: absolute;

  &__white-big {
    top: 50%;
    left: -49px;

    transform: translateY(-50%);

    @include mobile {
      top: auto;
      left: auto;
      right: 62px;
      bottom: 106px;

      transform: translateY(0);
    }
  }

  &__white {
    width: 70px;
    height: 78px;

    top: -32px;
    right: 132px;

    @include mobile {
      top: 9px;
      right: 90px;
    }
  }

  &__green-big {
    bottom: 61px;
    right: -44px;

    @include mobile {
      bottom: -31px;
      right: auto;
      left: 68px;
    }
  }

  &__green {
    width: 60px;
    height: 64px;

    bottom: -42px;
    left: 49px;

    @include mobile {
      top: auto;
      right: auto;

      bottom: 243px;
      left: 46px;
    }
  }
}
</style>
